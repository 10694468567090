<template>
  <div>
    <el-card style="min-height: 45rem">
      <div class="flex-def" style="width: 100%">
        <el-row :gutter="20" style="width: 100%">
          <el-col :span="3">
            <el-input v-model="searchForm.order_no" placeholder="订单号"></el-input>
          </el-col>
          <el-col :span="3">
            <el-input v-model="searchForm.phone" placeholder="购买人手机号"></el-input>
          </el-col>
          <el-col :span="3">
            <el-input v-model="searchForm.delivery_order_no" placeholder="发货单号"></el-input>
          </el-col>
          <el-col :span="4">
            <el-select v-model="searchForm.status">
              <el-option label="全部" :value="0"></el-option>
              <el-option label="待付款" :value="1"></el-option>
              <el-option label="待发货" :value="2"></el-option>
              <el-option label="待收货" :value="3"></el-option>
              <el-option label="已完成" :value="4"></el-option>
            </el-select>
          </el-col>
          <el-col :span="9">
            <div class="flex-def flex-zBetween">
              <el-button-group>
                <el-button @click="searchGo" type="primary">搜索</el-button>
                <el-button @click="searchReset">重置</el-button>
              </el-button-group>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-loading="loading" style="font-size: .8rem;width: 100%;min-height: 10rem;margin-top: 1rem">
        <div v-if="!list.length && !loading" class="y-desc flex-def flex-zCenter flex-cCenter"
             style="font-size: 2rem;height: 10rem">没有订单
        </div>
        <div class="order-item-box" v-for="(v,k) in list" :key="k">
          <div class="flex-def order-item-top" style="font-size: .8rem">
            <div class="order-item-top-item">订单ID:{{ v.id }}</div>
            <div class="order-item-top-item">订单编号:{{ v.order_no }}</div>
            <div class="order-item-top-item">下单时间:{{ v.created_at | date }}</div>
            <div class="order-item-top-item">支付时间:{{ v.pay_time | date }}</div>
          </div>
          <div class="flex-def order-item-center">
            <div class="order-item-center-item">
              <div class="flex-def order-item-center-goods">
                <img :src="getGoodsLog(v).pic | tomedia" style="height: 5rem;width: 5rem;border-radius: .5rem">
                <div style="margin-left: .5rem">
                  <div>{{ getGoodsLog(v).title }}</div>
                  <div>数量：{{ v.goods_num }}</div>
                </div>
              </div>
            </div>
            <div class="order-item-center-item flex-def flex-zTopBottom flex-zCenter">
              <div class="order-item-center-pay-info">下单人：({{ v.uid }}){{ v.user ? v.user.nickname : "" }}</div>
              <div class="order-item-center-pay-info">支付方式：{{ payTypeFormatter(v) }}</div>
            </div>
            <div class="order-item-center-item flex-def flex-zTopBottom flex-zCenter" style="font-size: .8rem">
              <div class="order-item-center-pay-info-box flex-def flex-zTopBottom flex-zCenter">
                <div class="order-item-center-pay-info">商品积分：{{ getGoodsLog(v).points }}</div>
                <div class="order-item-center-pay-info">商品价格：{{ getGoodsLog(v).fee }}</div>
                <div class="order-item-center-pay-info">运费：{{ v.delivery_fee }}</div>
                <div class="order-item-center-pay-info">应付现：{{ v.real_pay }}</div>

                <!--                <div class="order-item-center-pay-info">应付款：{{ value.fee_total }}</div>-->
<!--                <div class="order-item-center-pay-info">数量：{{ v.goods_num }}</div>-->
              </div>
            </div>
            <div class="order-item-center-item flex-def flex-zTopBottom flex-zCenter"
                 style="font-size: 1.6rem;color: #3dc0a5">
              {{ statusFormatter(v) }}
            </div>
            <div class="order-item-center-item flex-def flex-zTopBottom flex-zCenter flex-cCenter">
              <el-button @click="wantSend(v)" v-if="v.status==2" class="order-button">发货</el-button>
              <!--&lt;!&ndash;            <el-button @click="handPay(s.row.id)" v-if="s.row.status==0" size="mini">确认付款</el-button>&ndash;&gt;-->
              <el-button @click="handFinished(v.id)" v-if="v.status==3" class="order-button">确认收货</el-button>
            </div>
          </div>
          <div class="flex-def order-item-bottom">
            <div class="order-item-center-item flex-def flex-cCenter" style="width: 50%;position: relative">
              <span>{{ addressShow(v).name }}</span>
              <span style="margin-left: .5rem">{{ addressShow(v).phone }}</span>
              <span style="margin-left: .5rem">{{ addressShow(v).address }}</span>
              <el-button @click="$message.info('复制成功')"
                         v-clipboard:copy="`${addressShow(v).name}:${addressShow(v).phone}\n${addressShow(v).address}`"
                         class="order-button"
                         style="position:absolute;right: .5rem;">复制
              </el-button>
            </div>
            <div class="order-item-center-item" style="width: 25%">发货时间:{{ v.send_time | date }}</div>
            <div class="order-item-center-item" style="width: 10%">快递单号</div>
            <div class="order-item-center-item" style="width: 15%">{{ v.delivery_order_no }}</div>
          </div>
        </div>
      </div>
      <el-pagination v-if="!loading" style="padding: .5rem" background layout="total,prev, pager,next"
                     :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
      </el-pagination>
    </el-card>
    <el-dialog title="发货" :visible.sync="sendBox" @close="sendBoxClose">
      <div>
        <el-form label-width="70px">
          <el-form-item label="快递单号">
            <el-input v-model="sendForm.delivery_order_no"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="sendBox = false">取 消</el-button>
        <el-button type="primary" @click="sendSubmit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {PayTypeNum2Str} from "@/common/mod/order";

export default {
  name: "order",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      size: 15,
      sendForm: {},
      searchForm: {
        order_no: "",
        phone: "",
        delivery_order_no: "",
        status: 0,
      },
      sendBox: false,
      loading: false,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    getGoodsLog(item){
      return  item.goods_log ? JSON.parse(item.goods_log) : {}
    },
    payTypeFormatter(e) {
      return PayTypeNum2Str(e.pay_type);
    },
    searchGo() {
      this.page = 1;
      this.list.length = 0;
      this.load();
    },
    searchReset() {
      this.searchForm = {
        order_no: "",
        phone: "",
        delivery_order_no: "",
        status: 0,
      }
      this.searchGo()
    },
    addressShow(item) {
      let v = JSON.parse(item.address_log);
      v.full = "";
      let arr = [];
      if(v.province_label)arr.push(v.province_label);
      if(v.city_label)arr.push(v.city_label);
      if(v.district_label)arr.push(v.district_label);
      if(v.street_label)arr.push(v.street_label);
      if(v.detail)arr.push(v.detail);
      return {
        name: v.name,
        phone: v.phone,
        address: v.full || arr.join("")
      }
    },
    sendSubmit() {
      this.$u.api.shop.plugin.pointsMall.orderSend(this.sendForm).then(() => {
        this.$message.success("操作成功");
        this.sendBox = false;
        this.load();
      })
    },
    sendBoxClose() {
      this.sendForm = {}
      this.sendBox = false;
    },
    wantSend(item) {
      this.sendForm = {...item};
      this.sendBox = true;
    },
    handFinished(id) {
      this.$u.api.shop.plugin.pointsMall.orderHandsFinished({id}).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
    handPay(id) {
      this.$u.api.shop.plugin.pointsMall.orderHandsPay({id}).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
    statusFormatter({status}) {
      let str = "";
      switch (parseInt(status)) {
        case 1:
          str = "待付款";
          break;
        case 2:
          str = "待发货";
          break;
        case 3:
          str = "待收货";
          break;
        case 4:
          str = "已完成";
          break;
      }
      return str;
    },
    load() {
      this.loading = true;
      this.$u.api.shop.plugin.pointsMall.orderSearch({
        page: this.page,
        delivery_order_no: this.searchForm.delivery_order_no,
        order_no: this.searchForm.order_no,
        phone: this.searchForm.phone,
        status: this.searchForm.status,
      }).then(res => {
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>
.order-button {
  background-color: #3dc0a5;
  color: #FFFFFF
}

.order-item-box {
  border: 1px solid #ededed;
  margin-bottom: .5rem
}

.order-item-top {
  border-bottom: 1px solid #ededed;
  background-color: #ededed;
}

.order-item-top-item {
  padding: .5rem;
  width: 25%;
  white-space: nowrap;
  font-weight: 600;
}

.order-item-top-item:first-child {
  width: 10%;
}
.order-item-top-item + .order-item-top-item{
  border-left: 1px solid #f8f8f8;
}

.order-item-center-item + .order-item-center-item {
  border-left: 1px solid #ededed;
}

.order-item-center {
  border-bottom: 1px solid #ededed;
}

.order-item-center-item {
  padding: .5rem;
  width: 20%;
  box-sizing: border-box;
}

.order-item-center-item:first-child {
  width: 30%;
}

.order-item-center-item:nth-child(2) {
  width: 30%;
}

.order-item-center-item:nth-child(3),.order-item-center-item:nth-child(4), .order-item-center-item:nth-child(5) {
  width: 15%;
}

.order-item-center-goods + .order-item-center-goods,
.order-item-center-pay-info-box + .order-item-center-pay-info-box {
  margin-top: .5rem;
  border-top: 1px solid #ededed;
  padding-top: .5rem;
}

.order-item-center-pay-info {
  margin-top: .2rem;
}

.order-item-center-pay-info + .order-item-center-pay-info {
  margin-top: .2rem;
}

.order-item-center-pay-info-box {
  height: 100%;
}
</style>